import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="psionic-powers-adapt-body-to-crystallize"></a><h2>Psionic Powers</h2>
    <h2 style={{
      "marginTop": "-40px",
      "marginBottom": "0px"
    }}><span className="subtitle">Adapt Body&mdash;Crystallize</span></h2>
    <h6><a id="adapt-body"></a>Adapt Body</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychometabolism</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Psion/wilder 5,
psychic warrior 5
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Visual
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1
standard action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Personal
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> You
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 1 hour/level (D)
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 9
    <p>Your body automatically adapts to hostile environments. You
can adapt to underwater, extremely hot, extremely cold, or airless
environments, allowing you to survive as if you were a creature native
to that environment. You can breathe and move (though penalties to
movement and attacks, if any for a particular environment, remain), and
you take no damage simply from being in that environment. You need not
specify what environment you are adapting to when you manifest this
power; simply activate it, and your body will instantly adapt to any
hostile environment as needed throughout the duration.</p>
    <p>You can somewhat adapt to extreme environmental features such
as acid, lava, fire, and electricity. Any environmental feature that
normally directly deals 1 or more dice of damage per round deals you
only half the usual amount of damage.</p>
    <h6><a id="affinity-field"></a>Affinity Field</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychometabolism</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Psion/wilder 9
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Material and
visual
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1
standard action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 20 ft.
    <span style={{
      "fontWeight": "bold"
    }}>Area:</span> 20-ft.-radius
emanation, centered on you
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 1 round/level
(D)
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Fortitude
negates (potentially harmless)
    <span style={{
      "fontWeight": "bold"
    }}>Power Resistance:</span> Yes
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 17
    <p>You create an affinity feedback loop with all creatures within
the area. While the duration lasts, affected creatures take all damage
(including <a href="abilitiesAndConditions.html#ability-damaged" style={{
        "color": "rgb(87, 158, 182)"
      }}>ability damage</a>) as you do and
heal all wounds as you do. Hit
points gained or lost persist after this power ends.</p>
    <p>Creatures in range are also subject to magical and psionic
effects of 3rd level or lower. Creatures that have an affinity to you
gain a saving throw against each new power transferred through the
affinity field as if the power were manifested upon them normally. All
magical and psionic effects transferred to subjects fade at the end of
this power&rsquo;s duration, although instantaneous effects remain. If
you
suddenly become immune to a particular effect or power, the effect or
power to which you are immune cannot be transferred to creatures that
have affinity to you.</p>
    <h6><a id="anchored-navigation"></a>Anchored Navigation</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Clairsentience</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Seer 4
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Material and
olfactory
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1
standard action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Personal
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> You
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 1 hour/level
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 7
    <p>You know where you are in relation to a fixed starting point,
which is essential for setting up a mishap-free teleport beacon. While
the duration lasts, you are aware of your exact distance and route
(physical or psychoportive) back to a fixed starting point. The
&ldquo;anchored&rdquo; starting point is your exact location when you
manifest the
power. To designate other anchored starting points, you must manifest
this power multiple times and be present at the desired locations when
you do so.</p>
    <p>You can also retrace your steps through a maze automatically
while the power lasts, without resorting to a map.</p>
    <p>Anchored navigation grants you a <a href="psionicPowersGtoP.html#mindlink" style={{
        "color": "rgb(87, 158, 182)"
      }}>mindlink</a> with one designated
creature who remains within a 60-foot radius of the starting point,
regardless of the distance between you and the creature. The use of
anchored navigation is confined to the plane of existence where you
manifest it.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Augment:</span> If you spend
6 additional power points, the effect of this power extends across all
planar boundaries.</p>
    <h6><a id="animal-affinity"></a>Animal Affinity</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychometabolism</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Egoist 2, psychic
warrior 2
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Material
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1
standard action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Personal
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> You
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 1 min./level
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 3
    <p>You forge a psychometabolic affinity with an idealized animal
form, thereby boosting one of your ability scores (choose either
Strength, Dexterity, Constitution, Intelligence, Wisdom, or Charisma).
The power grants a +4 enhancement bonus to the ability score you
choose, adding the usual benefits provided by a high ability bonus.
Because you are emulating the idealized form of an animal, you also
take on minor aspects of the animal you choose. If you choose to
increase the ability you use to manifest powers, you do not gain the
benefit of an increased ability score long enough to gain any bonus
power points for a high ability score, but the save DCs of your powers
increase for the duration of this power.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Augment:</span> For every 5
additional power points you spend, this power grants a +4 enhancement
bonus to another ability.</p>
    <h6><a id="apopsi"></a>Apopsi</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy [Mind-Affecting]</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Psion/wilder 9
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Auditory,
material, and visual
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1 round
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Close (25 ft. + 5
ft./2 levels)
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> One living
psionic creature
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Fortitude
negates
    <span style={{
      "fontWeight": "bold"
    }}>Power Resistance:</span> Yes
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 17, XP
    <p>By using this power, you delete 1d4 powers permanently from
the subject&rsquo;s mind. You specify the level of each power, and the
DM
randomly determines which of the subject&rsquo;s powers is actually
deleted. <a href="psionicPowersGtoP.html#psychic-chirurgery" style={{
        "color": "rgb(87, 158, 182)"
      }}>Psychic chirurgery</a> or <a href="psionicPowersQtoW.html#reality-revision" style={{
        "color": "rgb(87, 158, 182)"
      }}>reality revision</a> can be used to
restore the lost
powers, but it must be performed within 1 week of losing the powers.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>XP Cost:</span> 50 XP per
level of the deleted powers.</p>
    <h6><a id="assimilate"></a>Assimilate</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychometabolism</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Psion/wilder 9
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Auditory and
visual
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1
standard action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Touch
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> One living
creature touched
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous
and 1 hour; see text
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Fortitude
half
    <span style={{
      "fontWeight": "bold"
    }}>Power Resistance:</span> Yes
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 17
    <p>Your pointing finger turns black as obsidian. A creature
touched by you is partially assimilated into your form and takes 20d6
points of damage. Any creature reduced to 0 or fewer hit points by this
power is killed, entirely assimilated into your form, leaving behind
only a trace of fine dust. An assimilated creature&rsquo;s equipment is
unaffected.</p>
    <p>A creature that is partially assimilated into your form (that
is, a creature that has at least 1 hit point following your use of this
power) grants you a number of temporary hit points equal to half the
damage you dealt for 1 hour.</p>
    <p>A creature that is completely assimilated grants you a number
of temporary hit points equal to the damage you dealt and a +4 bonus to
each of your ability scores for 1 hour. If the assimilated creature
knows psionic powers, you gain knowledge of one of its powers for 1
hour. You gain some semblance of a creature you completely assimilate
for 1 hour, granting you a +10 bonus on <a href="skillsAll.html#disguise" style={{
        "color": "rgb(87, 158, 182)"
      }}>Disguise</a>
checks made to appear
as that creature during that time.</p>
    <h6><a id="astral-caravan"></a>Astral Caravan</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychoportation</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Nomad 3
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> None
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1 hour
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Personal
    <span style={{
      "fontWeight": "bold"
    }}>Targets:</span> You and touched
willing creatures
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> See text
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 5
    <p>You lead a caravan into the Astral Plane, leaving the Material
Plane behind. Since the Astral Plane touches upon other planes, you can
travel astrally to any of these other planes as you wish, but only if
you know your way (see below).</p>
    <p>You can bring other willing creatures with you, provided that
these subjects have each manifested <a href="psionicPowersAtoC.html#astral-traveler" style={{
        "color": "rgb(87, 158, 182)"
      }}>astral traveler</a> and are linked
hand
to hand with you at the time of the astral caravan&rsquo;s
manifestation.
These fellow travelers are dependent upon you and must accompany you at
all times. If something happens to you during the journey that causes
you to break hand-to-hand contact, your companions are stranded
wherever you leave them. Because the planes are a dangerous place,
those who lead astral caravans usually choose to manifest this power
only if they have a large party of travelers assembled. Sometimes
groups of lower-level adventurers may hire you to lead forays beyond
the Material Plane.</p>
    <p>The astral caravan power lasts while you and your fellow
travelers maintain your original formation, until (1) you reach your
intended destination plane, (2) you desire to end the power while still
traversing the Astral Plane, (3) you or anyone traveling with you
breaks the hand-to-hand chain connecting the travelers for 2
consecutive rounds, or (4) the power is terminated by some outside
means, such as <a href="psionicPowersDtoF.html#dispel-psionics" style={{
        "color": "rgb(87, 158, 182)"
      }}>dispel psionics</a>. When the power
ends, you and your
fellow travelers halt in whatever portion of the Astral Plane you
happen to be traversing (the Astral Plane is in many ways subjective in
location; in any event, one place on the Astral looks much like any
other).</p>
    <p>While you are traveling through the Astral Plane, those
natives who happen to glimpse you and your fellow travelers perceive
you to be moving at a speed of 30 feet (you can&rsquo;t run), with you
flying
in the lead and your fellow travelers strung out behind you, each
linked to the next by one hand.</p>
    <p>Depending on your knowledge of the planes, your journey
through the subjective space that is the Astral Plane may take a longer
or shorter period of time. For each 24 hours you travel, make a <a href="skillsAll.html#knowledge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Knowledge</a>
(the planes) check.
Unless a location is particularly hard to
find and well guarded, or conversely easy to find and well advertised,
the average DC for an astral caravan journey should be set at 20. You
cannot take 20 on this check, though you can take 10. Each check may be
modified by your degree of familiarity with the destination or by some
connection you have with the place; see the tables below.</p>
    <span style={{
      "fontWeight": "bold"
    }}>Destination Knowledge (the
planes)</span>&nbsp;&nbsp;&nbsp; 
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "350px"
          }}>Knowledge</th>
          <th style={{
            "width": "110px"
          }}>Check Modifier</th>
        </tr>
        <tr className="odd-row">
          <td>None*
          </td>
          <td>n/a
          </td>
        </tr>
        <tr>
          <td>Secondhand (you have heard of the destination)</td>
          <td>-10
          </td>
        </tr>
        <tr className="odd-row">
          <td>Firsthand (you have visited before)</td>
          <td>+0
          </td>
        </tr>
        <tr>
          <td>Familiar (you have visited three or more times)</td>
          <td>+5
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }} rowSpan="1" colSpan="2">*<span style={{
              "fontStyle": "italic"
            }}>If you have no direct knowledge of your
destination, you must have some connection to it in order to travel
there; see below.</span></td>
        </tr>
      </tbody>
    </table>
    <span style={{
      "fontWeight": "bold"
    }}>Knowledge (the planes)</span>&nbsp;&nbsp;&nbsp;
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "350px"
          }}>Connection</th>
          <th style={{
            "width": "110px"
          }}>Check Modifier</th>
        </tr>
        <tr className="odd-row">
          <td>Likeness or picture of destination</td>
          <td>+2
          </td>
        </tr>
        <tr>
          <td>Object from destination</td>
          <td>+4
          </td>
        </tr>
        <tr className="odd-row">
          <td>Planar Cartographer&rsquo;s map of destination</td>
          <td>+10
          </td>
        </tr>
      </tbody>
    </table>
    <p>Each successful check indicates that you are one step closer
to your goal. To finally arrive at your location, you must succeed on
six checks within a span of 12 days. (If you fail to make six
successful checks within the first 12 days, you can continue to make
one check per day until you get the requisite six successes within a
span of 12 consecutive days). When you successfully make the requisite
number of checks, the journey ends, and you appear on your chosen plane
within 10-1,000 (1d% x 10) miles of your intended destination on that
plane.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Augment:</span> If you spend
2 additional power points, this power weaves a quasi-real filmy
membrane around yourself and all those adjacent to each other in the
caravan (being linked by hand is not required if this membrane is
used). You remain visible within the translucent, amorphous enclosure.
You can pick up or drop willing passengers, easily reaching through the
film. Anything you hold is enveloped by the film. Any attacks made
through the enclosure in either direction have a 25% miss chance due to
the rippling membrane.</p>
    <p>When you manifest this power in its augmented form, your
apparent speed to those observing on the Astral Plane is 40 feet, and
you can make a Knowledge (the planes) check once every 12 hours to
attempt to make it to your destination (six successful checks within 12
consecutive days still sees you to your desired goal).</p>
    <h6><a id="astral-construct"></a>Astral Construct</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Metacreativity (Creation)</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Shaper 1
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Visual; see text
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1 round
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Close (25 ft. + 5
ft./2 levels)
    <span style={{
      "fontWeight": "bold"
    }}>Effect:</span> One created
astral construct
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 1 round/level
(D)
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None
    <span style={{
      "fontWeight": "bold"
    }}>Power Resistance:</span> No
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 1
    <p>This power creates one 1st-level astral construct of
solidified ectoplasm that attacks your enemies. It appears where you
designate and acts immediately, on your turn. It attacks your opponents
to the best of its ability. As a free action, you can mentally direct
it not to attack, to attack particular enemies, or to perform other
actions. The astral construct acts normally on the last round of the
power&rsquo;s duration and dissipates at the end of its turn.</p>
    <p>Astral constructs are not summoned; they are created on the
plane you inhabit (using ectoplasm drawn from the Astral Plane). Thus,
they are not subject to effects that hedge out or otherwise affect
outsiders; they are constructs, not outsiders.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Augment:</span> For every 2
additional power points you spend, the level of the astral construct
increases by one.</p>
    <h6><a id="astral-seed"></a>Astral Seed</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Metacreativity</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Shaper 8
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Material; see
text
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 10
minutes
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 0 ft.
    <span style={{
      "fontWeight": "bold"
    }}>Effect:</span> One storage
crystal
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None
    <span style={{
      "fontWeight": "bold"
    }}>Power Resistance:</span> No
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 15
    <p>This power weaves strands of astral ectoplasm into a crystal
containing the seed of your living mind (hardness 1 and 1 hit point).
You can have only one astral seed in existence at any one time. Until
such time as you perish, the astral seed (also called the storage
crystal) is utterly inert. If you are slain at some later date, your
soul transfers into the storage crystal, which begins to dimly glow.</p>
    <p>Upon transference, your physical remains (should they still
exist) become inert matter and cannot thereafter be restored to life.
The transfer from the slain body to the astral seed works over any
distance, physical or extradimensional.</p>
    <p>Once your body&rsquo;s physical demise activates the storage
crystal, you have the abilities of a psicrystal of the appropriate
level, plus all the powers you knew and the maximum power points you
possessed when astral seed was manifested-but you also have a negative
level that cannot be healed and that does not convert to real level
loss in your current crystalline form. You have thirty days to grow an
organic body, after which time your sentience fades and your soul
passes on if it hasn&rsquo;t entered a new body.</p>
    <p>To grow a body, you (in the storage crystal) must spend ten
days in uninterrupted solitude. The body&rsquo;s constituent parts are
pulled
as ectoplasm from the Astral Plane, then slowly molded and transformed
into a living, breathing body that is an exact duplicate of your body
at the time you manifested astral seed (the crystal itself breaks down
and becomes a part of the new organic body). When the tenth day ends,
you completely and totally inhabit the new body. You possess all the
abilities you possessed when astral seed was manifested, at one level
lower, but you have none of your equipment.</p>
    <p>If the body is struck for any amount of damage during the
ten-day period when it is growing, it is destroyed and your soul passes
on.</p>
    <p>Conceivably, you could manifest <a href="psionicPowersGtoP.html#mind-switch" style={{
        "color": "rgb(87, 158, 182)"
      }}>mind switch</a> to utilize a
temporary body, but only an evil creature would smash his own
temporarily empty storage crystal to permanently usurp a
subject&rsquo;s
organic body (unless the subject is itself irredeemably evil).</p>
    <h6><a id="astral-traveler"></a>Astral Traveler</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychoportation</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Psion/wilder 1,
psychic warrior 1
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> None
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1 hour
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Touch
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> Creature touched
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> See text
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Will
negates (harmless)
    <span style={{
      "fontWeight": "bold"
    }}>Power Resistance:</span> Yes
(harmless)
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 1
    <p>This power allows you or a creature you touch to participate
in an astral caravan created through use of the <a href="psionicPowersAtoC.html#astral-caravan" style={{
        "color": "rgb(87, 158, 182)"
      }}>astral caravan</a> power.
While participating in a journey allowed by the astral caravan power,
you must hold the hand of both your fellow passenger ahead of you (or
the caravan leader) and your fellow passenger behind you (unless you
happen to be the last in the hand-linked line). If you or any one of
your fellow passengers breaks the hand-to-hand link for 2 consecutive
rounds, the impetus through the Astral Plane provided by astral caravan
fails. See the astral caravan power for more information.</p>
    <p>All those who are part of the caravan who are capable of
performing purely mental actions, such as manifesting a power, may do
so while maintaining hand-to-hand contact with their fellow travelers.
When astral travelers begin their journey, each one is connected to the
Material Plane by an insubstantial silvery cord. Very few weapons exist
that can damage a silvery cord.</p>
    <p>The last creature in the line of those making up the caravan
is sometimes referred to as the rear guard, because he or she has one
hand free and can use it to wield a weapon without relinquishing his or
her grip on the next traveler in line. The weightless, subjective
environment of the Astral Plane allows the caravan to flex and bend as
necessary to bring the rear guard&rsquo;s weapon to bear.</p>
    <h6><a id="attraction"></a>Attraction</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy (Charm)
[Mind-Affecting]</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Psion/wilder 1
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Auditory
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1
standard action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Close (25 ft. + 5
ft./2 levels)
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> One creature
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 1 hour/level
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Will negates
    <span style={{
      "fontWeight": "bold"
    }}>Power Resistance:</span> Yes
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 1
    <p>You plant a compelling attraction in the mind of the subject.
The attraction can be toward a particular person or an object. The
subject will take reasonable steps to meet, get close to, attend, or
find the object of its implanted attraction. For the purpose of this
power, &ldquo;reasonable&rdquo; means that, while attracted, the
subject doesn&rsquo;t
suffer from blind obsession. He will act on this attraction only when
not engaged in combat. The subject won&rsquo;t perform obviously
suicidal
actions. He can still recognize danger but will not flee unless the
threat is immediate. If you make the subject feel an attraction to
yourself, you can&rsquo;t command him indiscriminately, although he
will be
willing to listen to you (even if he disagrees). This power grants you
a +4 bonus on any interaction checks you make involving the subject
(such as <a href="skillsAll.html#bluff" style={{
        "color": "rgb(87, 158, 182)"
      }}>Bluff</a>, <a href="skillsAll.html#diplomacy" style={{
        "color": "rgb(87, 158, 182)"
      }}>Diplomacy</a>,
      <a href="skillsAll.html#intimidate" style={{
        "color": "rgb(87, 158, 182)"
      }}>Intimidate</a>, and <a href="skillsAll.html#sense-motive" style={{
        "color": "rgb(87, 158, 182)"
      }}>Sense
Motive</a>).</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Augment:</span> For every 2
additional power points you spend, this power&rsquo;s save DC increases
by 1
and the bonus on interaction checks increases by 1.</p>
    <h6><a id="aura-alteration"></a>Aura Alteration</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy [Mind-Affecting]</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Psion/wilder 6
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Material
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1
standard action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Medium (100 ft. +
10 ft./ level)
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> One willing
creature
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 10 min./level
or instantaneous; see text
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 11
    <p>You can use this power in one of two ways: to disguise the
subject&rsquo;s aura (alignment) and level, or to remove a compulsion
or
charm effect from the subject.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Disguise:</span> If you use
this power to disguise the subject&rsquo;s
alignment and level, the power has a duration of 10 minutes per level.
You can change the subject&rsquo;s alignment by only one step. You can
adjust
the subject&rsquo;s apparent level up or down by a number equal to
one-half
your own level (rounded down) or less.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Remove Compulsion:</span> If
you use this power to attempt to cleanse
the subject&rsquo;s aura of a baleful or controlling effect, the
duration is
instantaneous. This power can remove the compulsion of a curse or a
geas/quest effect. It can also negate any charm and compulsion powers
of 6th level or lower, such as <a href="psionicPowersAtoC.html#crisis-of-breath" style={{
        "color": "rgb(87, 158, 182)"
      }}>crisis of breath</a> or <a href="psionicPowersDtoF.html#death-urge" style={{
        "color": "rgb(87, 158, 182)"
      }}>death urge</a>. When
aura alteration is manifested for this purpose, the subject gains
another saving throw to remove the compulsion afflicting it against the
original save DC, but with a +2 bonus.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Augment:</span> You can
augment this power in one or both of the following ways.</p>
    <p>1. For every additional power point you spend, the duration of
the disguise aura increases by 10 minutes.</p>
    <p>2. If you spend 2 additional power points, the subject&rsquo;s
alignment shifts an additional step; if you spend 4 additional power
points, the subject&rsquo;s alignment changes to its opposite.</p>
    <h6><a id="aura-sight"></a>Aura Sight</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Clairsentience</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Psion/wilder 4
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Visual
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1
standard action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 60 ft.
    <span style={{
      "fontWeight": "bold"
    }}>Area:</span> Cone-shaped
emanation centered on you
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Concentration,
up to 10 min./level
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None
    <span style={{
      "fontWeight": "bold"
    }}>Power Resistance:</span> No
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 7
    <p>You discern auras. Auras are invisible to the naked eye, but
to a psionic viewer manifesting this power they appear as glowing halos
or envelopes of colored light that surround all objects. The color of
each aura reveals information to the psionic character. The amount of
information revealed depends on how long you study a particular area.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>1st Round:</span> Presence
of good and evil auras in the area. You
can&rsquo;t pin an aura to a particular object or individual at this
stage;
instead, you see a colored haze suffusing the area. (This power can
detect lawful and chaotic auras as well, but doing so requires a
separate manifestation that focuses only on that alignment axis.)</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>2nd Round:</span> Number of
auras (creatures, objects, powers, or
spells) in the area. You know how many auras are in the area, even
though each aura doesn&rsquo;t resolve to its actual location quite yet.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>3rd Round:</span> The owner
of each aura is revealed, unless the
individual is outside your line of sight. If a creature whose aura you
detect has 5 or more Hit Dice than you do, you are overwhelmed by its
presence and dazed for 1 round, and the power ends.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Augment:</span> For every 2
additional power points you spend, this power&rsquo;s range increases
by 5
feet.</p>
    <h6><a id="aversion"></a>Aversion</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy (Compulsion)
[Mind-Affecting]</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Telepath 2
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Auditory and
material
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1
standard action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Close (25 ft. + 5
ft./2 levels)
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> One creature
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 1 hour/level
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Will negates
    <span style={{
      "fontWeight": "bold"
    }}>Power Resistance:</span> Yes
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 3
    <p>You plant a powerful aversion in the mind of the subject. If
the object of the implanted aversion is an individual or a physical
object, she will prefer not to approach within 30 feet of it. If it is
a word, she will try not to utter it; if it is an action, she will not
willingly attempt to perform it; and if it is an event, she will not
willingly attend it. The subject will take reasonable steps to avoid
the object of its aversion, but will not put herself in jeopardy by
doing so.</p>
    <p>If the subject is forced into taking an action she has an
aversion to, she takes a -2 penalty on any attack rolls, ability
checks, or skill checks involved.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Augment:</span> For every 2
additional power points you spend, this power&rsquo;s save DC increases
by 1
and the duration increases by 1 hour.</p>
    <h6><a id="baleful-teleport"></a>Baleful Teleport</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychoportation (Teleportation)</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Nomad 5
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Material and
visual
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1
standard action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Close (25 ft. + 5
ft./2 levels)
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> One corporeal
creature
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Fortitude
half
    <span style={{
      "fontWeight": "bold"
    }}>Power Resistance:</span> Yes
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 9
    <p>You psychoportively disperse minuscule portions of the
subject, dealing 9d6 points of damage. Targets can be protected from
the effects of baleful teleport by <a href="psionicPowersDtoF.html#dimensional-anchor-psionic" style={{
        "color": "rgb(87, 158, 182)"
      }}>dimensional anchor</a>.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Augment:</span> For every
additional power point you spend, this power&rsquo;s damage increases
by 1d6
points. For each extra 2d6 points of damage, this power&rsquo;s save DC
increases by 1 and your manifester level increases by 1 for the purpose
of overcoming power resistance.</p>
    <h6><a id="banishment-psionic"></a>Banishment, Psionic</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychoportation</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Nomad 6
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Auditory and
material
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1
standard action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Close (25 ft. + 5
ft./2 levels)
    <span style={{
      "fontWeight": "bold"
    }}>Targets:</span> One or more
extraplanar creatures, no two of which can be more than 30 ft. apart
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Will negates
    <span style={{
      "fontWeight": "bold"
    }}>Power Resistance:</span> Yes
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 11
    <p>As the <a href="spellsAtoB.html#banishment" style={{
        "color": "rgb(87, 158, 182)"
      }}>banishment</a> spell, except as
noted here. </p>
    <p>Psions gain no benefit from presenting objects or substances
that the target hates, fears, or otherwise opposes.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Augment:</span> For every 2
additional power points you spend, this power&rsquo;s save DC increases
by 1
and your manifester level increases by 1 for the purpose of overcoming
power resistance.</p>
    <h6><a id="bend-reality"></a>Bend Reality</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Clairsentience</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Psion/wilder 8
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Visual
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1
standard action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> See text
    <span style={{
      "fontWeight": "bold"
    }}>Target, Effect, or Area:</span>
See text
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> See text
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None; see
text
    <span style={{
      "fontWeight": "bold"
    }}>Power Resistance:</span> Yes
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 15, XP
    <p>Bend reality lets you create nearly any type of effect. For
example, bend reality can do any of the following:</p>
    <ul>
      <li>
        <p>Duplicate any psion power of 6th level or lower, provided
the power is not of a discipline prohibited to you.</p>
      </li>
      <li>
        <p>Duplicate any other power (but not a spell) of 5th level
or lower, provided the power is not of a discipline prohibited to you.</p>
      </li>
      <li>
        <p>Duplicate any <a href="PsionicPsionList.html" style={{
            "color": "rgb(87, 158, 182)"
          }}>psion power</a> of 5th level or
lower, even if
it&rsquo;s of a prohibited discipline.</p>
      </li>
      <li>
        <p>Duplicate any other power (but not a spell) of 4th level
or lower, even if it&rsquo;s of a prohibited discipline.</p>
      </li>
      <li>
        <p>Undo the harmful effects of many powers, such as <a href="psionicPowersDtoF.html#dominate-psionic" style={{
            "color": "rgb(87, 158, 182)"
          }}>psionic
dominate</a>, <a href="spellsFtoG.html#geas-quest" style={{
            "color": "rgb(87, 158, 182)"
          }}>geas/quest</a>, or <a href="psionicPowersGtoP.html#insanity" style={{
            "color": "rgb(87, 158, 182)"
          }}>insanity</a>.</p>
      </li>
      <li>
        <p>Produce any other effect whose power level is in line with
the above effects, such as a single creature automatically hitting on
its next attack or taking a -8 penalty on its next saving throw. </p>
      </li>
    </ul>
    <p>A duplicated power allows saving throws and power resistance
as normal (but the save DC is for a 8th-level power).</p>
    <p>When bend reality duplicates a power that has an XP cost, you
must pay that cost or 300 XP, whichever is more. When bend reality
duplicates a spell with a material component, you must pay additional
XP equal to the value of the material component divided by 5.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>XP Cost:</span> 300 XP or
more (see above).</p>
    <h6><a id="bestow-power"></a>Bestow Power</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy [Mind-Affecting]</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Psion/wilder 2
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Mental
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1
standard action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 20 ft.
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> One psionic
creature
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None
    <span style={{
      "fontWeight": "bold"
    }}>Power Resistance:</span> No
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 3
    <p>You link your mind with another psionic creature&rsquo;s mind,
creating a brief conduit through which mental energy can be shared.
When you manifest this power, the subject gains up to 2 power points.
You can transfer only as many power points to a subject as it has
manifester levels.</p>
    <p>Because of the intimate nature of this power, it cannot be
fabricated into a psionic item - only power points generated by a
psionic
creature in the moment can be shared using bestow power.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Augment:</span> For every 3
additional power points you spend, the subject gains 2 additional power
points.</p>
    <h6><a id="biofeedback"></a>Biofeedback</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychometabolism</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Psion/wilder 2,
psychic warrior 1
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Material and
visual
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1
standard action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Personal
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> You
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 1 min./level (D)
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span>
Psion/wilder 3, psychic warrior 1
    <p>You can toughen your body against wounds, lessening their
impact. During the duration of this power, you gain <a style={{
        "color": "#579eb6"
      }} href="abilitiesAndConditions.html#damage-reduction">damage
reduction</a>
2/-.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Augment:</span> For every 3
additional power points you spend, your damage reduction increases by 1.</p>
    <h6><a id="bite-of-the-wolf"></a>Bite of the Wolf</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychometabolism</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Psychic warrior 1
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Visual; see text
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1
standard action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Personal
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> You
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 1 min./level
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 1
    <p>Your posture becomes stooped forward, and you grow a muzzle
complete with fangs. You gain one bite attack each round, instead of or
in addition to any other attacks you have, that deals 1d8 points of
damage (assuming you are a Medium creature) when it hits.</p>
    <p>Your bite attack is a natural weapon, so you are considered
armed when attacking with it, and it can be affected by powers, spells,
and effects that enhance or improve natural weapons. You can choose to
deal nonlethal damage with your bite, taking the standard -4 penalty on
your attack roll. If you bite as your only attack, you use your highest
base attack bonus on the attack roll, and you can apply your full
Strength bonus to damage. If you bite in addition to making other
attacks, the bite is a secondary attack.</p>
    <p>If you are not a Medium creature, your bite attack&rsquo;s
base
damage varies as follows: Fine 1d2, Diminutive 1d3, Tiny 1d4, Small
1d6, Large 2d6, Huge 2d8, Gargantuan 4d6, Colossal 6d6.</p>
    <p>Based on your psychic warrior level, your bite increases in
ferocity as noted here: at 5th level your bite deals an extra 1d8
points of damage, at 10th level an extra 2d8, at 15th level an extra
3d8, and at 20th level an extra 4d8 points.</p>
    <h6><a id="body-adjustment"></a>Body Adjustment</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychometabolism (Healing)</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Psion/wilder 3,
psychic warrior 2
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Auditory and
material
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1 round
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Personal
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> You
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span>
Psion/wilder 5, psychic warrior 3
    <p>You take control of your body&rsquo;s healing process, curing
yourself of 1d12 points of damage. As usual, when regular damage is
healed, an equal amount of nonlethal damage is also healed.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Augment:</span> For every 2
additional power points you spend, this power heals an additional 1d12
points of damage.</p>
    <h6><a id="body-equilibrium"></a>Body Equilibrium</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychometabolism</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Psion/wilder 2,
psychic warrior 2
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Material and
visual
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1
standard action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Personal
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> You
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 10 min./level
(D)
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 3
    <p>You can adjust your body&rsquo;s equilibrium to correspond
with any
solid or liquid that you stand on. Thus, you can walk on water,
quicksand, or even a spider&rsquo;s web without sinking or breaking
through
(this effect does not confer any resistance to particularly sticky
webs). You can move at your normal speed, but you cannot run (x4 speed)
on an unfirm surface without sinking or breaking through.</p>
    <p>If you fall from any height while using this power, damage
from the impact is halved.</p>
    <h6><a id="body-purification"></a>Body Purification</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychometabolism (Healing)</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Psion/wilder 3,
psychic warrior 2
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Auditory and
material
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1 round
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Personal
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> You
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span>
Psion/wilder 5, psychic warrior 3
    <p>You restore up to 2 points of <a href="abilitiesAndConditions.html#ability-damaged" style={{
        "color": "rgb(87, 158, 182)"
      }}>damage</a> to a single ability
score. You cannot use body purification to heal <a href="abilitiesAndConditions.html#ability-drained" style={{
        "color": "rgb(87, 158, 182)"
      }}>ability drain</a>.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Augment:</span> For every
additional power point you spend, this power heals 1 additional point
of damage to the same ability score.</p>
    <h6><a id="bolt"></a>Bolt</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Metacreativity (Creation)</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Psion/wilder 1
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Material
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1
standard action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 0 ft.
    <span style={{
      "fontWeight": "bold"
    }}>Effect:</span> A normal bolt,
arrow, or sling bullet
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 1 min./level
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None
    <span style={{
      "fontWeight": "bold"
    }}>Power Resistance:</span> No
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 1
    <p>You create 2d4 ectoplasmic crossbow bolts, arrows, or sling
bullets, appropriate to your size, which dissipate into their
constituent ectoplasmic particles when the duration ends or after being
fired. Ammunition you create has a +1 enhancement bonus on attack rolls
and damage rolls.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Augment:</span> For every 3
additional power points you spend, this power improves the
ammunition&rsquo;s
enhancement bonus on attack rolls and damage rolls by 1.</p>
    <h6><a id="brain-lock"></a>Brain Lock</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy (Compulsion)
[Mind-Affecting]</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Telepath 2
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Material and
visual
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1
standard action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Medium (100 ft. +
10 ft./ level)
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> One humanoid
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Concentration +
1 round
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Will negates
    <span style={{
      "fontWeight": "bold"
    }}>Power Resistance:</span> Yes
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 3
    <p>The subject&rsquo;s higher mind is locked away. He is <a href="abilitiesAndConditions.html#dazed" style={{
        "color": "rgb(87, 158, 182)"
      }}>dazed</a> and cannot take psionic
actions (including manifesting powers or
using psionic feats that require a decision to be used) for the
duration of the power.</p>
    <p>A brain locked subject is not stunned, so attackers get no
special advantage against him.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Augment:</span> You can
augment this power in one or both of the following ways.</p>
    <p>1. If you spend 2 additional power points, this power can also
affect an animal, fey, giant, magical beast, or monstrous humanoid.</p>
    <p>2. If you spend 4 additional power points, this power can also
affect an aberration, dragon, elemental, or outsider in addition to the
creature types mentioned above.</p>
    <h6><a id="breath-of-the-black-dragon"></a>Breath of the Black
Dragon</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychometabolism [Acid]</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Psion/wilder 6,
psychic warrior 6
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Visual
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1
standard action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Close (25 ft. + 5
ft./2 levels)
    <span style={{
      "fontWeight": "bold"
    }}>Area:</span> Cone-shaped burst
centered on you
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Reflex half
    <span style={{
      "fontWeight": "bold"
    }}>Power Resistance:</span> Yes
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 11
    <p>Your mouth spews forth vitriolic acid that deals 11d6 points
of acid damage to any targets in the area.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Augment:</span> For every
additional power point you spend, this power&rsquo;s damage increases
by 1d6
points.</p>
    <h6><a id="burst"></a>Burst</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychoportation</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Nomad 1, psychic
warrior 1
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Auditory
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1 swift
action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Personal
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> You
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 1 round
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 1
    <p>This power increases your land speed by 10 feet. (This
adjustment counts as an enhancement bonus to speed.)</p>
    <p>You can manifest this power with an instant thought, quickly
enough to gain the benefit of the power on your turn before you move.
Manifesting this power is a swift action, like manifesting a quickened
power, and it counts toward the normal limit of one quickened power per
round. You cannot manifest this power when it isn&rsquo;t your turn.</p>
    <h6><a id="call-to-mind"></a>Call to Mind</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy [Mind-Affecting]</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Psion/wilder 1
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Mental
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1 minute
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Personal
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> You
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 1
    <p>By meditating on a subject, you can recall natural memories
and knowledge otherwise inaccessible to you.</p>
    <p>On a failed <a href="skillsAll.html#knowledge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Knowledge</a> check, you can
manifest this power to
gain a new check with a +4 competence bonus. If successful, you
instantly recall what was previously buried in your subconscious.</p>
    <h6><a id="call-weaponry"></a>Call Weaponry</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychoportation (Teleportation)</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Psychic warrior 1
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Material
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1 round
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 0 ft.
    <span style={{
      "fontWeight": "bold"
    }}>Effect:</span> One weapon; see
text
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 1 min./level;
see text (D)
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None
    <span style={{
      "fontWeight": "bold"
    }}>Power Resistance:</span> No
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 1
    <p>You call a weapon &ldquo;from thin air&rdquo; into your
waiting hand
(actually, it is a real weapon hailing from another location in space
and time). You don&rsquo;t have to see or know of a weapon to call
it-in
fact, you can&rsquo;t call a specific weapon; you just specify the
kind. If
you call a projectile weapon, it comes with 3d6 nonmagical bolts,
arrows, or sling bullets, as appropriate. The weapon is made of
ordinary materials as appropriate for its kind. If you relinquish your
grip on the weapon you called for 2 or more consecutive rounds, it
automatically returns to wherever it originated.</p>
    <p>Weapons gained by call weaponry are distinctive due to their
astral glimmer. They are considered magic weapons and thus are
effective against <a style={{
        "color": "#579eb6"
      }} href="abilitiesAndConditions.html#damage-reduction">damage reduction</a> that requires a
magic weapon to
overcome.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Augment:</span> For every 4
additional power points you spend, this power improves the
weapon&rsquo;s
enhancement bonus on attack rolls and damage rolls by 1.</p>
    <h6><a id="catapsi"></a>Catapsi</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy [Mind-Affecting]</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Psion/wilder 5,
psychic warrior 5
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Mental and visual
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1
standard action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 30 ft.
    <span style={{
      "fontWeight": "bold"
    }}>Area:</span> 30-ft.-radius
emanation centered on you
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 1 round/level
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Will
negates; see text
    <span style={{
      "fontWeight": "bold"
    }}>Power Resistance:</span> Yes
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 9
    <p>By manifesting this power, you generate psychic static,
interfering with the ability of other psionic characters to manifest
their powers or use psi-like abilities (you are not affected by your
own catapsi manifestation). All psionic activity within the area
requires 4 more power points to manifest than normal, unless a
character makes a Will save each time he attempts to manifest a power.
Using a psi-like ability becomes a full-round action, instead of a
standard action, in a catapsi field. If two or more fields of catapsi
overlap, the effects are not cumulative.</p>
    <p>The limit on the number of power points a subject can spend on
a power remains in effect; thus, a subject may not be able to manifest
its highest-level powers. If manifesting a power would cause the
manifester to exceed his available power points or his spending limits,
the manifestation fails automatically, but no power points are expended.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Augment:</span> For every 4
additional power points you spend, this power&rsquo;s range and the
radius of
its area both increase by 5 feet.</p>
    <h6><a id="catfall"></a>Catfall</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychoportation</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Psion/wilder 1,
psychic warrior 1
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Auditory
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1
immediate action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Personal
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> You
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Until landing
or 1 round/ level
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 1
    <p>You recover instantly from a fall and can absorb some damage
from falling. You land on your feet no matter how far you fall, and you
take damage as if the fall were 10 feet shorter than it actually is.
This power affects you and anything you carry or hold (up to your
maximum load). You can manifest this power with an instant thought,
quickly enough to gain the benefit of the power while you fall.
Manifesting the power is an immediate action. You can manifest this
power even when it isn&rsquo;t your
turn.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Augment:</span> For every
additional power point you spend, this power reduces your damage as if
the fall were an additional 10 feet shorter.</p>
    <h6><a id="chameleon"></a>Chameleon</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychometabolism</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Egoist 2, psychic
warrior 1
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Olfactory
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1
standard action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Personal
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> You
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 10 min./level
(D)
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 1
    <p>Your skin and equipment take on the color and texture of
nearby objects, including floors and walls. You receive a +10
enhancement bonus on <a href="skillsAll.html#hide" style={{
        "color": "rgb(87, 158, 182)"
      }}>Hide</a> checks.</p>
    <h6><a id="charm-psionic"></a>Charm, Psionic</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy (Charm)
[Mind-Affecting]</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Telepath 1
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Mental
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1
standard action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Close (25 ft. + 5
ft./2 levels)
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> One humanoid
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 1 hour/level
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Will negates
    <span style={{
      "fontWeight": "bold"
    }}>Power Resistance:</span> Yes
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 1
    <p>As the <a href="spellsC.html#charm-person" style={{
        "color": "rgb(87, 158, 182)"
      }}>charm person</a> spell, except as
noted here.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Augment:</span> You can
augment this power in one or more of the following ways.</p>
    <p>1. If you spend 2 additional power points, this power can also
affect an animal, fey, giant, magical beast, or monstrous humanoid.</p>
    <p>2. If you spend 4 additional power points, this power can also
affect an aberration, dragon, elemental, or outsider in addition to the
creature types mentioned above.</p>
    <p>3. If you spend 4 additional power points, this power&rsquo;s
duration increases to one day per level.</p>
    <p>In addition, for every 2 additional power points you spend to
achieve any of these effects, this power&rsquo;s save DC increases by 1.</p>
    <h6><a id="clairtangent-hand"></a>Clairtangent Hand</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Clairsentience (Scrying)</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Seer 5
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Auditory,
mental, and visual
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1
standard action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> See text
    <span style={{
      "fontWeight": "bold"
    }}>Area:</span> See text
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Up to 1
min./level; see text (D)
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None
    <span style={{
      "fontWeight": "bold"
    }}>Power Resistance:</span> No
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 9
    <p>You can emulate a <a href="psionicPowersDtoF.html#far-hand" style={{
        "color": "rgb(87, 158, 182)"
      }}>far hand</a> effect at any distance
while
simultaneously emulating <a href="#clairvoyant-sense" style={{
        "color": "rgb(87, 158, 182)"
      }}>clairvoyant sens</a>e to target your
far hand; see
the appropriate power descriptions. Clairtangent hand&rsquo;s duration
is up
to 1 minute per level when used with a far hand effect.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Augment:</span> If you spend
8 additional power points, you can emulate clairvoyant sense in
conjunction with either <a href="psionicPowersQtoW.html#telekinetic-force" style={{
        "color": "rgb(87, 158, 182)"
      }}>telekinetic force</a>, <a href="psionicPowersQtoW.html#telekinetic-maneuver" style={{
        "color": "rgb(87, 158, 182)"
      }}>telekinetic maneuver</a>, or <a href="psionicPowersQtoW.html#telekinetic-thrust" style={{
        "color": "rgb(87, 158, 182)"
      }}>telekinetic thrust</a>, but this
power&rsquo;s duration expires as soon as any of
the noted telekinetic powers deals damage.</p>
    <h6><a id="clairvoyant-sense"></a>Clairvoyant Sense</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Clairsentience (Scrying)</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Seer 2
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Auditory and
visual
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1
standard action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> See text
    <span style={{
      "fontWeight": "bold"
    }}>Effect:</span> Psionic sensor
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 1 min./level (D)
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None
    <span style={{
      "fontWeight": "bold"
    }}>Power Resistance:</span> No
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 3
    <p>You can see and hear a distant location almost as if you were
there. You don&rsquo;t need line of sight or line of effect, but the
locale
must be known-a place familiar to you or an obvious one, such as behind
a door, around a corner, or in a grove of trees. Once you have selected
the locale, the focus of your clairvoyant sense doesn&rsquo;t move, but
you
can rotate it in all directions to view the area as desired. Unlike
other scrying powers, this power does not allow psionically or
supernaturally enhanced senses to work through it.</p>
    <p>If the chosen locale is magically or psionically dark, you see
nothing. If it is naturally pitch black, you can see in a 10- foot
radius around the center of the power&rsquo;s effect or out to the
extent of
your natural darkvision. The power does not work across planes.</p>
    <h6><a id="claw-of-energy"></a>Claw of Energy</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychokinesis [see text]</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Psychic warrior 4
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Visual
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1
standard action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Personal
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> You
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 1 round/level
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 7
    <p>If you have a claw attack (either from an actual natural
weapon or from an effect such as <a href="#claws-of-the-beast" style={{
        "color": "rgb(87, 158, 182)"
      }}>claws of the beast</a>), you can use
this
power to energize that weapon. The claw attack deals an extra 1d6
points of cold, electricity, or fire damage (as chosen by you at the
time of manifestation) on a successful hit. On a critical hit, it deals
an extra 1d10 points of energy damage. If the claw&rsquo;s critical
multiplier is x3, add 2d10 points of energy damage instead; if the
multiplier is x4, add 3d10 points of energy damage.</p>
    <p>This power can be manifested on a claw attack that already
deals energy damage, but if the claw already deals the same type of
damage as the power, the effects stack. If this power is manifested on
a claw attack already benefiting from the effect of the power, the
newer manifestation supersedes the older manifestation, even if both
manifestations are of different energy types.</p>
    <p>This power&rsquo;s subtype is the same as the type of energy
infused
in the natural weapon.</p>
    <h6><a id="claws-of-the-beast"></a>Claws of the Beast</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychometabolism</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Psychic warrior 1
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Visual
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1 swift
action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Personal
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> You
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 1 hour/level
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> see text
    <p>You call forth the aggressive nature of the beast inherent in
yourself, psionically transforming your hands into deadly claws. You
gain two natural attacks with your claws, each dealing 1d4 points of
damage (1d6 if you are Large, or 1d3 if you are Small) plus your
Strength bonus.</p>
    <p>Your claws are natural weapons, so you are considered armed
when attacking with them, and they can be affected by powers, spells,
and effects that enhance or improve natural. You can choose to deal
nonlethal damage with your claws, taking the standard -4 penalty on
your attack roll.</p>
    <p>Your claws work just like the natural weapons of many
monsters. You can make an attack with one claw or a full attack with
two claws at your normal attack bonus, replacing your normal attack
routine. You take no penalties for two-weapon fighting, and neither
attack is a secondary attack. If your base attack bonus is +6 or
higher, you do not gain any additional attacks-you simply have two claw
attacks at your normal attack bonus.</p>
    <p>You can manifest this power with an instant thought, quickly
enough to gain the benefit of the power on your turn before you attack.
Manifesting this power is a swift action, like manifesting a quickened
power, and it counts toward the normal limit of one quickened power per
round. You cannot manifest this power when it isn&rsquo;t your turn.</p>
    <p>You can call or dismiss the claws as a swift action during the
duration of the power. If you attack with a manufactured weapon or
another natural attack, you can&rsquo;t make any claw attacks in that
round.
You can still hold and manipulate items with your claws or cast spells
just as well as you could with your hands.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Augment:</span> If you spend
additional power points, you can create larger, sharper, and more
deadly claws, as shown on the table below.</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr valign="bottom">
          <th style={{
            "width": "75px"
          }}>Power Points
          </th>
          <th rowSpan="1" colSpan="3" style={{
            "width": "125px"
          }}>Claw Damage
          </th>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "60px"
          }}><span style={{
              "fontWeight": "bold"
            }}>Small</span>
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "60px"
          }}><span style={{
              "fontWeight": "bold"
            }}>Medium</span>
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "60px"
          }}><span style={{
              "fontWeight": "bold"
            }}>Large</span>
          </td>
        </tr>
        <tr className="odd-row">
          <td>1
          </td>
          <td>1d3
          </td>
          <td>1d4
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>1d6
          </td>
        </tr>
        <tr>
          <td>3
          </td>
          <td>1d4
          </td>
          <td>1d6
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>1d8
          </td>
        </tr>
        <tr className="odd-row">
          <td>5
          </td>
          <td>1d6
          </td>
          <td>1d8
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>2d6
          </td>
        </tr>
        <tr>
          <td>7
          </td>
          <td>1d8
          </td>
          <td>2d6
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>3d6
          </td>
        </tr>
        <tr className="odd-row">
          <td>11
          </td>
          <td>2d6
          </td>
          <td>3d6
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>4d6
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>15
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>3d6
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>4d6
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>5d6
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)",
            "color": "rgb(0, 0, 0)"
          }}>19
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)",
            "color": "rgb(0, 0, 0)"
          }}>4d6
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)",
            "color": "rgb(0, 0, 0)"
          }}>5d6
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)",
            "color": "rgb(0, 0, 0)"
          }}>6d6
          </td>
        </tr>
      </tbody>
    </table>
    <h6><a id="claws-of-the-vampire"></a>Claws of the Vampire</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychometabolism</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Psychic warrior 3
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Material and
visual
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1
standard action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Personal
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> You
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 1 round/level
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 5
    <p>If you have a claw attack (either from an actual natural
weapon or from an effect such as <a href="#claws-of-the-beast" style={{
        "color": "rgb(87, 158, 182)"
      }}>claws of the beast</a>), you can use
this
power to change the nature of that weapon. When this power is
manifested, your claws take on an ominous glimmer. Each time you make a
successful claw attack against a living creature of Small or larger
size, you are healed of some amount of damage.</p>
    <p>You heal a number of hit points equal to half the base damage
dealt by your claws of the vampire, rounded down (additional damage
dealt because of a high Strength score or other enhancements does not
count toward the amount you heal). You heal as many hit points as can
be gained while the creature remains at 1 hit point or higher. Any
damage that would reduce the creature to 0 or fewer hit points does not
benefit you.</p>
    <p>You do not heal damage if your attack deals nonlethal damage,
such as when you attack a creature that has the <a href="monsterTypes.html#regeneration" style={{
        "color": "rgb(87, 158, 182)"
      }}>regeneration</a>
ability.
Moreover, you gain no healing from attacking any creature that is under
the effect of <a href="#biofeedback" style={{
        "color": "rgb(87, 158, 182)"
      }}>biofeedback</a>.
Using <a href="psionicPowersDtoF.html#fission" style={{
        "color": "rgb(87, 158, 182)"
      }}>fission</a> on yourself and then
attacking
your duplicate also fails to grant any healing.</p>
    <h6><a id="cloud-mind"></a>Cloud Mind</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy [Mind-Affecting]</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Psion/wilder 2
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> None
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1
standard action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Close (25 ft. +5
ft./2 levels)
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> One creature
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 1 min./level
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Will negates
    <span style={{
      "fontWeight": "bold"
    }}>Power Resistance:</span> Yes
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 3
    <p>You make yourself completely undetectable to the subject by
erasing all awareness of your presence from its mind. This power has
the following effects.</p>
    <p>First, you are <a href="abilitiesAndConditions.html#invisible" style={{
        "color": "rgb(87, 158, 182)"
      }}>invisible</a> and inaudible to the
creature. It
cannot even detect your presence by means of <a href="monsterTypes.html#blindsense" style={{
        "color": "rgb(87, 158, 182)"
      }}>blindsense</a>,
      <a href="monsterTypes.html#blindsight" style={{
        "color": "rgb(87, 158, 182)"
      }}>blindsight</a>, <a href="monsterTypes.html#scent" style={{
        "color": "rgb(87, 158, 182)"
      }}>scent</a>,
or <a href="monsterTypes.html#tremorsense" style={{
        "color": "rgb(87, 158, 182)"
      }}>tremorsense</a>.
It cannot pinpoint your location by any means.</p>
    <p>Second, the subject remains unaware of your actions, provided
you do not make any attacks or cause any obvious or directly
threatening changes in the subject&rsquo;s environment. If you attack
the
subject creature, the effect ends.</p>
    <p>If you take an action that creates a sustained and obvious
change in the subject&rsquo;s environment - for example, attacking a
creature
aside from the subject or moving a large or attended object the subject
can see - the subject immediately gains a new saving throw against the
power. An ally of the subject creature that is able to see or perceive
you can use a move action to warn the subject and thereby grant it a
new saving throw.</p>
    <h6><a id="cloud-mind-mass"></a>Cloud Mind, Mass</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy [Mind-Affecting]</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Psion/wilder 6
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> One creature/level
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 11
    <p>As <a href="#cloud-mind" style={{
        "color": "rgb(87, 158, 182)"
      }}>cloud
mind</a>, except as noted above. Each creature is
affected individually.</p>
    <h6><a id="compression"></a>Compression</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychometabolism</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Psychic warrior 1
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Olfactory
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1
standard action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Personal
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> You
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 1 round/level
(D)
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 1
    <p>This power causes instant diminution, halving your height,
length, and width and dividing your weight by 8. This decrease changes
your size category to the next smaller one. You gain a +2 size bonus to
Dexterity, a -2 size penalty to Strength (to a minimum effective
Strength score of 1), a +1 size bonus on attack rolls, and a +1 size
bonus to Armor Class due to your reduced size. If your new size is
Tiny, you have a space of 2 1/2 feet and a natural reach of 0 feet
(meaning that you must enter an opponent&rsquo;s square to attack). If
your
new size is Diminutive, you have a space of 1 foot and a natural reach
of 0 feet. This power doesn&rsquo;t change your speed. </p>
    <p>All your equipment, worn or carried, is similarly reduced by
the power. Melee and projectile weapons deal less damage. Other psionic
or magical properties are not affected by this power. Any affected item
that leaves your possession (including a projectile or thrown weapon)
instantly returns to its normal size. This means that thrown weapons
deal their normal damage (projectiles deal damage based on the size of
the weapon that fired them). Multiple effects that reduce size do not
stack, which means (among other things) that you can&rsquo;t use a
second
manifestation of this power to further reduce yourself.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Augment:</span> You can
augment this power in one or more of the following ways.</p>
    <p>1. If you spend 6 additional power points, this power
decreases your size by two size categories. You gain a +4 size bonus to
Dexterity, a -4 size penalty to Strength (to a minimum effective
Strength score of 1), a +2 size bonus on attack rolls, and a +2 size
bonus to Armor Class due to your reduced size.</p>
    <p>2. If you spend 6 additional power points, you can manifest
this power as a swift action instead of a standard action.</p>
    <p>3. If you spend 2 additional power points, this power&rsquo;s
duration is 1 minute per level rather than 1 round per level.</p>
    <h6><a id="conceal-thoughts"></a>Conceal Thoughts</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy [Mind-Affecting]</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Psion/wilder 1,
psychic warrior 1
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Mental
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1
standard action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Close (25 ft. + 5
ft./2 levels)
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> One willing
creature
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 1 hour/level
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Will
negates (harmless)
    <span style={{
      "fontWeight": "bold"
    }}>Power Resistance:</span> Yes
(harmless)
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 1
    <p>You protect the subject&rsquo;s thoughts from analysis. While
the
duration lasts, the subject gains a +10 circumstance bonus on <a href="skillsAll.html#bluff" style={{
        "color": "rgb(87, 158, 182)"
      }}>Bluff</a>
checks against those attempting to discern its true intentions with <a href="skillsAll.html#sense-motive" style={{
        "color": "rgb(87, 158, 182)"
      }}>Sense
Motive</a>. It also gains a +4
bonus on its saving throw against any
power or spell used to read its mind (such as <a href="psionicPowersQtoW.html#read-thoughts" style={{
        "color": "rgb(87, 158, 182)"
      }}>read thoughts</a> or <a href="psionicPowersGtoP.html#mind-probe" style={{
        "color": "rgb(87, 158, 182)"
      }}>mind
probe</a>).</p>
    <h6><a id="concealing-amorpha"></a>Concealing Amorpha</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Metacreativity (Creation)</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Psion/wilder 2,
psychic warrior 2
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Material; see
text
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1
standard action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> 0 ft.
    <span style={{
      "fontWeight": "bold"
    }}>Effect:</span> Quasi-real
amorphous film centered on you
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 1 min./level (D)
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 3
    <p>Using concealing amorpha, you weave a quasi-real membrane
around yourself. You remain visible within the translucent, amorphous
enclosure. This distortion grants you <a style={{
        "color": "#579eb6"
      }} href="combatModifiers.html#concealment">concealment</a> (opponents have a 20%
miss chance), thanks to the rippling membrane encasing your form. You
can pick up or drop objects, easily reaching through the film. Anything
you hold is enveloped by the amorpha. Likewise, you can engage in
melee, make ranged attacks, and manifest powers without hindrance.</p>
    <h6><a id="concealing-amorpha-greater"></a>Concealing Amorpha,
Greater</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Metacreativity (Creation)</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Shaper 3, psychic
warrior 3
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 1 round/level
(D)
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 5
    <p>As <a href="#concealing-amorpha" style={{
        "color": "rgb(87, 158, 182)"
      }}>concealing amorpha</a>, except the
quasi-real membrane so
distorts your image and actual position that you gain <a style={{
        "color": "#579eb6"
      }} href="combatModifiers.html#total-concealment">total concealment</a>
(opponents have a 50% miss chance), but for a shorter period of time.</p>
    <h6><a id="concussion-blast"></a>Concussion Blast</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychokinesis [Force]</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Psion/wilder 2
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Auditory
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1
standard action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Medium (100 ft. +
10 ft./ level)
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> One creature or
object
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None
    <span style={{
      "fontWeight": "bold"
    }}>Power Resistance:</span> Yes
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 3
    <p>A subject you select is pummeled with telekinetic force for
1d6 points of force damage. You can choose to have the power deal an
equal amount of nonlethal damage instead. Concussion blast always
affects a subject within range that you can see, even if the subject is
in melee or has cover or concealment (you cannot use this power against
creatures with total cover or total concealment).</p>
    <p>Nonmagical, unattended objects (including doors, walls, locks,
and so on) may also be damaged by this power.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Augment:</span> You can
augment this power in one or both of the following ways.</p>
    <p>1. For every 2 additional power points you spend, this
power&rsquo;s
damage increases by 1d6 points.</p>
    <p>2. For every 2 additional power points you spend, this power
can affect an additional target. Any additional target cannot be more
than 15 feet from another target of the power.</p>
    <h6><a id="contingency-psionic"></a>Contingency, Psionic</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Clairsentience</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Psion/wilder 6
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Olfactory
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 10
minutes or longer; see text
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Personal
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> You
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> One day/level
(D) or until discharged
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 11, XP
    <p>You can place another power upon your person so that it comes
into effect under some condition you dictate when manifesting psionic
contingency. The psionic contingency power and the companion power are
manifest at the same time. The 10-minute manifesting time is the
minimum total for both manifestations; if the companion power has a
manifesting time longer than 10 minutes, use that instead. The power to
be brought into effect by the psionic contingency must be one that
affects your person and be of a power level no higher than one-third
your manifester level (rounded down, maximum 6th level).</p>
    <p>The conditions needed to bring the power into effect must be
clear, although they can be general. In all cases, the psionic
contingency immediately brings into effect the companion power, the
latter being &ldquo;manifested&rdquo; instantaneously only when the
prescribed
circumstances occur. If complicated or convoluted conditions are
prescribed, the power combination (psionic contingency and the
companion power) may fail when called on.</p>
    <p>You can use only one psionic contingency companion power at a
time; if a second is manifested, the first one (if still active) is
dismissed.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>XP Cost:</span> 15 XP.</p>
    <h6><a id="control-air"></a>Control Air</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychokinesis</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Kineticist 2
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Mental
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1
standard action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Long (400 ft. + 40
ft./level)
    <span style={{
      "fontWeight": "bold"
    }}>Area:</span> 50-ft.-radius spread
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Concentration,
up to 1 min./level
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None
    <span style={{
      "fontWeight": "bold"
    }}>Power Resistance:</span> No
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 3
    <p>You have some control over wind speed and direction. The speed
of the wind within the area of this power can be increased or decreased
by up to 10 miles per hour.</p>
    <p>You can spend as many as 5 additional power points to augment
this power, with each point allowing you to modify the wind speed by an
additional 10 miles per hour, to a maximum change in wind speed of 60
miles per hour.</p>
    <p>This power also gives you the ability to alter the direction
of the wind by as much as 90 degrees.</p>
    <p>Powerful enough winds can cause creatures to be <a href="abilitiesAndConditions.html#blown-away" style={{
        "color": "rgb(87, 158, 182)"
      }}>blown away</a>, <a href="abilitiesAndConditions.html#knocked-down" style={{
        "color": "rgb(87, 158, 182)"
      }}>knocked down</a>, or <a href="abilitiesAndConditions.html#checked" style={{
        "color": "rgb(87, 158, 182)"
      }}>checked</a>.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Augment:</span> See above.</p>
    <h6><a id="control-body"></a>Control Body</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychokinesis</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Kineticist 4
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Material
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1
standard action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Medium (100 ft. +
10 ft./level)
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> One Medium or
smaller creature with humanoid physiology
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Concentration,
up to 1 min./level
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Fortitude
negates
    <span style={{
      "fontWeight": "bold"
    }}>Power Resistance:</span> Yes
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 7
    <p>You psychokinetically control the actions of any humanoid
(including undead or outsiders with a humanoid physiology) that is
within range and to which you have line of sight. Control body
doesn&rsquo;t
require mental contact with the subject, since you are actually forcing
limb movements independent of the target&rsquo;s mind. You can force
the
subject to stand up, sit down, walk, turn around, and so on, but
operating the vocal cords is too difficult. You can also hold the
subject immobile, rendering it <a href="abilitiesAndConditions.html#helpless" style={{
        "color": "rgb(87, 158, 182)"
      }}>helpless</a>. You cannot force the
subject
to manifest powers, cast spells, or use any special ability that is not
a function of just its body movements. If you lose line of sight to the
subject, the effect of this power ends.</p>
    <p>If you force the subject to engage in combat, its attack bonus
is equal to your base attack bonus + your Intelligence bonus, and its
bonus on damage rolls is equal to your Intelligence bonus. A subject of
this power cannot make attacks of opportunity. The subject gains no
benefit to Armor Class from its Dexterity, but it does gain a bonus to
its AC equal to your Intelligence bonus.</p>
    <p>Although the subject&rsquo;s body is under your control, the
subject&rsquo;s mind is not. Creatures capable of taking purely mental
actions (such as manifesting powers) can do so.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Augment:</span> For every 2
additional power points you spend, this power can affect a target one
size category larger.</p>
    <h6><a id="control-flames"></a>Control Flames</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychokinesis [Fire]</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Psion/wilder 1
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Auditory
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1
standard action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Medium (100 ft. +
10 ft./level)
    <span style={{
      "fontWeight": "bold"
    }}>Area:</span> One nonmagical fire
source; see text
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Concentration,
up to 1 min./level
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> See text
    <span style={{
      "fontWeight": "bold"
    }}>Power Resistance:</span> No
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 1
    <p>You pyrokinetically control the intensity or movements of one
fire source. A nonmagical fire source can be controlled if it is equal
to or smaller than the maximum size of fire you can control according
to your manifester level, as noted on the accompanying table. You can
freely switch control between fire sources, or change the nature of
your control, while you maintain concentration, but only one specified
change (keeping a fire burning, animating it, or altering its size) can
be made to one fire source in a round. When your control over a fire
source lapses, that fire immediately returns to its original state (or
goes out if it has no fuel or has been moved away from its original
location). With this power, you can artificially keep a fire burning
that would normally expire for lack of fuel; even dousing a controlled
flame with water does not put it out (though completely submerging the
flame would). Normally, a creature at risk of catching on fire can
avoid this fate by making a DC 15 Reflex saving throw, with success
indicating that the fire has gone out. If the fire is one that has been
kept burning by the use of control flames, then the DC of the Reflex
save needed to put out the flames increases to 25.</p>
    <p>This power also enables you to make a fire move as if it were
a living creature. You can animate only a naturally burning fire; if
you attempt to animate one that has been increased or decreased in size
by your augmentation of this power, the fire immediately returns to its
original size. An animated fire moves at a speed of 30 feet. A fire
that moves away from its fuel or its original location dies as soon as
your control over it lapses. </p>
    <p>An animated fire can enter any square, even if a creature
already occupies it. If an animated fire enters a square occupied by a
creature, that creature can make a Reflex save to get out of the way
(DC 11 + the number of dice of damage the fire does + your Int modifier
if you are a psion or your Cha modifier if you are a wilder). A
successful Reflex save moves the creature to the nearest unoccupied
square. The flames deal the indicated damage to any creature that is
either on fire or surrounded by the flames (in the fire&rsquo;s space);
see
the accompanying table).</p>
    <p>At the start of your turn, the animated fire deals damage to
any creature in its space, and the creature catches on fire unless it
makes a Reflex save (DC as noted above). A victim on fire takes 1d6
points of damage each round. Additional rounds in the same space as the
animated fire occupies mean additional chances of ignition. The damage
from multiple normal fires stacks, but the victim gets a saving throw
each round to negate each fire. It is possible to switch control from
the animated fire (causing it to disappear) to intensify flames that
are already burning (thus denying the foe Reflex saves after the
first).</p>
    <span style={{
      "fontWeight": "bold"
    }}>Control Flames</span>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <td style={{
            "fontWeight": "bold",
            "verticalAlign": "bottom"
          }}>Manifester
Level</td>
          <td style={{
            "width": "60px",
            "fontWeight": "bold",
            "verticalAlign": "bottom"
          }}>Fire
Size</td>
          <td style={{
            "fontWeight": "bold",
            "width": "100px",
            "verticalAlign": "bottom"
          }}>Example</td>
          <td style={{
            "fontWeight": "bold",
            "width": "150px",
            "verticalAlign": "bottom"
          }}>Maximum
Damage per Round</td>
          <td style={{
            "fontWeight": "bold",
            "verticalAlign": "bottom"
          }}>Space</td>
        </tr>
        <tr className="odd-row">
          <td>1st-2nd 
          </td>
          <td>Fine
          </td>
          <td>Tindertwig</td>
          <td style={{
            "verticalAlign": "top"
          }}>1</td>
          <td style={{
            "verticalAlign": "top"
          }}>1 foot
square</td>
        </tr>
        <tr>
          <td>3rd-4th</td>
          <td>Diminutive
          </td>
          <td>Torch</td>
          <td style={{
            "verticalAlign": "top"
          }}>1d3
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>1 foot
square</td>
        </tr>
        <tr className="odd-row">
          <td>5th-6th</td>
          <td>Tiny
          </td>
          <td>Small
campfire</td>
          <td style={{
            "verticalAlign": "top"
          }}>1d6
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>1 foot
square</td>
        </tr>
        <tr>
          <td>7th-8th</td>
          <td>Small
          </td>
          <td>Large
campfire</td>
          <td style={{
            "verticalAlign": "top"
          }}>2d6
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>1 foot
square</td>
        </tr>
        <tr className="odd-row">
          <td>9th-10th</td>
          <td>Medium</td>
          <td>Forge</td>
          <td style={{
            "verticalAlign": "top"
          }}>3d6
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>1 foot
square</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>11th-12th</td>
          <td style={{
            "verticalAlign": "top"
          }}>Large</td>
          <td style={{
            "verticalAlign": "top"
          }}>Bonfire</td>
          <td style={{
            "verticalAlign": "top"
          }}>4d6
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>2-by-2-foot
square</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "color": "rgb(0, 0, 0)",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>13th-14th</td>
          <td style={{
            "verticalAlign": "top",
            "color": "rgb(0, 0, 0)",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Huge</td>
          <td style={{
            "verticalAlign": "top",
            "color": "rgb(0, 0, 0)",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Burning
shack</td>
          <td style={{
            "verticalAlign": "top",
            "color": "rgb(0, 0, 0)",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>5d6
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>3-by-3-foot
square</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>15th-16th</td>
          <td style={{
            "verticalAlign": "top"
          }}>Gargantuan</td>
          <td style={{
            "verticalAlign": "top"
          }}>Burning
tavern</td>
          <td style={{
            "verticalAlign": "top"
          }}>6d6</td>
          <td style={{
            "verticalAlign": "top"
          }}>4-by-4-foot
square</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>17th
or higher</td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Colossal</td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Burning
inn</td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>7d6</td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>5-by-5-foot
square 
          </td>
        </tr>
      </tbody>
    </table>
    <p><span style={{
        "fontWeight": "bold"
      }}>Augment:</span> You can
augment this power in one or both of the following ways.</p>
    <p>1. For every 2 additional power points you spend, you can
increase the size of a fire you want to control by one step, up to the
maximum size of fire you can control according to your manifester level.</p>
    <p>2. For every 2 additional power points you spend, you can
decrease the size of a fire you want to control by one step. You can
reduce a Tiny or smaller fire to nothing, extinguishing it.</p>
    <h6><a id="control-light"></a>Control Light</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychokinesis [Light]</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Psion/wilder 1
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Visual
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1
standard action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Medium (100 ft. +
10 ft./level)
    <span style={{
      "fontWeight": "bold"
    }}>Area:</span> Nine 10-ft. cubes +
three 10-ft. cubes/level
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Concentration,
up to 1 min./level, or 1 round; see text
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None
    <span style={{
      "fontWeight": "bold"
    }}>Power Resistance:</span> No
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 1
    <p>By manipulating the ambient light level, you can decrease or
increase the illumination of an area. The change in illumination can be
gradual (taking as long as 1 minute) or sudden (occurring immediately
when you manifest this power). You can alter the level of illumination
from its original level at any time during the power&rsquo;s duration.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Decrease:</span> You can
decrease the illumination of an area by as
little as 5% (barely perceptible) or as much as 100% (total darkness).
If you decrease the light by 50% or more, the visual ability of
creatures that depend on light to see declines accordingly.If you
decrease the ambient light in an area by 100%, even those with lowlight
vision are unable to see within the affected area. For each 25%
decrease in ambient light, characters in the area gain a cumulative +1
circumstance bonus on <a href="skillsAll.html#hide" style={{
        "color": "rgb(87, 158, 182)"
      }}>Hide</a> checks (to a maximum of +4
when all the
light is gone).</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Increase:</span> You can
increase the illumination of an area by as
little as 5% (barely perceptible) or as much as 100%. If you increase
the light by 50% or more, the visual ability of creatures that depend
on light to see improves accordingly.</p>
    <p>You can use this power to increase the illumination of an area
by 200%&nbsp; (improving visual abilities accordingly), but in such a
case the power&rsquo;s duration is only 1 round.</p>
    <h6><a id="control-object"></a>Control Object</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychokinesis</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Kineticist 1
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Material
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1
standard action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Medium (100 ft. +
10 ft./ level)
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> One unattended
object weighing up to 100 lb.
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Concentration,
up to 1 round/level
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw: </span>None
    <span style={{
      "fontWeight": "bold"
    }}>Power Resistance:</span> No
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 1
    <p>You telekinetically &ldquo;bring to life&rdquo; an inanimate
object.
Though it is not actually alive, the object moves under your control.
Live vegetation cannot be controlled in this fashion, nor can already
animated objects or undead. The controlled object moves like a puppet,
with jerky and clumsy movements, at a speed of up to 20 feet. If its
form is rigid, it makes creaking, groaning, or grating sounds as you
control it.</p>
    <p>A controlled object can attack an opponent if you direct it to
do so. It has one slam attack, at a base attack bonus equal to your
base attack bonus plus your Intelligence modifier. If the attack hits,
it deals points of damage equal to 1d6 plus your Intelligence modifier.
A controlled object has its usual hardness and hit points.</p>
    <p>You can use this power on a nonmagical lock, making it move in
such a way as to attempt to unlock itself. If another character makes
an <a href="skillsAll.html#open-lock" style={{
        "color": "rgb(87, 158, 182)"
      }}>Open
Lock</a> check involving a lock that you are concentrating on
controlling, the character gains a +4 bonus on the check.</p>
    <h6><a id="control-sound"></a>Control Sound</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychokinesis [Sonic]</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Psion/wilder 2
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Auditory; see
text
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1
standard action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Medium (100 ft. +
10 ft./ level)
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> One sound or
mixture of related sounds
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Concentration,
up to 1 min./level; see text
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None
    <span style={{
      "fontWeight": "bold"
    }}>Power Resistance:</span> No
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 3
    <p>You shape and alter existing sounds. You can target one sound,
such as a person speaking or singing, or a group of related sounds,
such as the patter of many raindrops or the tramp of soldiers passing
by. A sound as quiet as a snapping finger can be controlled. You can
substitute any sound you have heard for the target sound. If you
attempt to exactly duplicate the voice of a specific individual, or an
inherently terrifying sound (such as a dragon&rsquo;s roar), you must
succeed
on a <a href="skillsAll.html#bluff" style={{
        "color": "rgb(87, 158, 182)"
      }}>Bluff</a>
check with a +5 circumstance bonus opposed by the intended
listener&rsquo;s <a href="skillsAll.html#sense-motive" style={{
        "color": "rgb(87, 158, 182)"
      }}>Sense Motive</a> check to avoid
arousing suspicion.</p>
    <p>You can entirely muffle a noise or magnify a sound to such
loudness that it drowns out all other conversation in the immediate
area. In this way, you can provide yourself or any with a +4
circumstance bonus on <a href="skillsAll.html#move-silently" style={{
        "color": "rgb(87, 158, 182)"
      }}>Move Silently</a> and <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a>
checks.</p>
    <p>Alternatively, you can use up the power in an instant. You do
this by modulating a sound into a one-time destructive impetus that
shatters nonmagical/nonpsionic, unattended objects of crystal, glass,
ceramics, or porcelain (vials, bottles, flasks, jugs, mirrors, and so
forth) in the area.</p>
    <h6><a id="co-opt-concentration"></a>Co-Opt Concentration</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy (Compulsion)
[Mind-Affecting]</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Psion/wilder 6
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Mental
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1
standard action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Medium (100 ft. +
10 ft./ level)
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> One creature
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Concentration,
up to 1 round/level
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Will negates
    <span style={{
      "fontWeight": "bold"
    }}>Power Resistance:</span> Yes
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 11
    <p>You take over control of a power that was manifested by the
subject and that must be maintained through concentration. Once you
wrest control of the power from the subject, you have several options.</p>
    <ul>
      <li>
        <p>Allow the power to function as normal.</p>
      </li>
      <li>
        <p>Keep the power targeted on the subject (if a personal
power) but decide how the power fulfills its function each round.</p>
      </li>
      <li>
        <p>Retarget the power on yourself (if a personal power).</p>
      </li>
      <li>
        <p>Choose not to concentrate on the co-opted power in the
next round, ending the power at that point.</p>
      </li>
    </ul>
    <p>When the duration of co-opt concentration expires, the power
you took control of ends (even if this would mean that the power ends
earlier than normal).</p>
    <h6><a id="correspond"></a>Correspond</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy [Mind-Affecting]</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Psion/wilder 4
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Mental
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 10
minutes
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> See text
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> One creature with
an Intelligence score of 3 or higher
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 1 round/level
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None
    <span style={{
      "fontWeight": "bold"
    }}>Power Resistance:</span> No
    <span style={{
      "fontWeight": "bold"
    }}>Power Points: </span>7
    <p>You forge a passive mental link with a creature with which you
have previously had physical or mental contact. The subject need not be
within sight or even on the same plane as you are. The subject
recognizes you, and you can mentally communicate with it for the
duration (though nothing forces the subject to respond to you),
exchanging messages of twenty-five words or less once per round.
Receiving a message is not an action and does not provoke attacks of
opportunity; however, sending a message is equivalent to a standard
action that can provoke attacks of opportunity.</p>
    <h6><a id="create-sound"></a>Create Sound</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Metacreativity (Creation)
[Sonic]</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Psion/wilder 1
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Auditory; see
text
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1
standard action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Close (25 ft. + 5
ft./2 levels)
    <span style={{
      "fontWeight": "bold"
    }}>Effect:</span> Sounds; see text
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 1 round/level
(D)
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None
    <span style={{
      "fontWeight": "bold"
    }}>Power Resistance:</span> No
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 1
    <p>You create a volume of sound that rises, recedes, approaches,
or remains at a fixed place. You choose what type of sound the power
creates when manifesting it and cannot thereafter change its basic
character. The volume of sound created, however, depends on your level.
You can produce as much noise as four normal humans per manifester
level (maximum twenty humans). Thus, talking, singing, shouting,
walking, marching, or running sounds can be created. The noise produced
can be virtually any type of sound within the volume limit. A horde of
rats running and squeaking is about the same volume as eight humans
running and shouting. A roaring lion is equal to the noise from sixteen
humans, while a roaring dire lion is equal to the noise from twenty
humans.</p>
    <p>If you wish to create a specific message, up to twenty-five
words can be created, and those words repeat over and over until the
duration expires or the power is dismissed. If you attempt to exactly
duplicate the voice of a specific individual or an inherently
terrifying sound (such as a dragon&rsquo;s roar), you must succeed on a
      <a href="skillsAll.html#bluff" style={{
        "color": "rgb(87, 158, 182)"
      }}>Bluff</a>
check with a +2 circumstance bonus opposed by the listener&rsquo;s <a href="skillsAll.html#sense-motive" style={{
        "color": "rgb(87, 158, 182)"
      }}>Sense
Motive</a> check to avoid arousing suspicion.</p>
    <p>Create sound can be used to bring sounds into existence that
you later manipulate by manifesting control sound.</p>
    <h6><a id="crisis-of-breath"></a>Crisis of Breath</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy (Compulsion)
[Mind-Affecting]</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Telepath 3
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Auditory
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1
standard action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Medium (100 ft. +
10 ft./ level)
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> One breathing
humanoid
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> 1 round/level
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Will
negates, Fortitude partial; see text
    <span style={{
      "fontWeight": "bold"
    }}>Power Resistance:</span> Yes
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 5
    <p>You compel the subject to purge its entire store of air in one
explosive exhalation, and thereby disrupt the subject&rsquo;s autonomic
breathing cycle. The subject&rsquo;s lungs do not automatically
function
again while the power&rsquo;s duration lasts.</p>
    <p>If the target succeeds on a Will save when crisis of breath is
manifested, it is unaffected by this power. If it fails its Will save,
it can still continue to breathe by taking a standard action in each
round to gasp for breath.</p>
    <p>An affected creature can attempt to take actions normally
(instead of consciously controlling its breathing), but each round it
does so, beginning in the round when it failed its Will save, the
subject risks blacking out from lack of oxygen. It must succeed on a
Fortitude save at the end of any of its turns in which it did not
consciously take a breath. The DC of this save increases by 1 in every
consecutive round after the first one that goes by without a breath;
the DC drops back to its original value if the subject spends an action
to take a breath.</p>
    <p>If a subject fails a Fortitude save, it is <a href="abilitiesAndConditions.html#disabled" style={{
        "color": "rgb(87, 158, 182)"
      }}>disabled</a> (0 hp). In
the following round, it drops to -1 hit points and is <a href="abilitiesAndConditions.html#dying" style={{
        "color": "rgb(87, 158, 182)"
      }}>dying</a>. Curing
powers or spells can revive a dying subject normally, so long as this
power&rsquo;s duration has expired; if the power is still in effect, a
revived creature is still subject to Fortitude saves in each round when
it does not consciously breathe.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Augment:</span> You can
augment this power in one or more of the following ways.</p>
    <p>1. If you spend 2 additional power points, this power can also
affect an animal, fey, giant, magical beast, or monstrous humanoid.</p>
    <p>2. If you spend 4 additional power points, this power can also
affect an aberration, dragon, elemental, or outsider in addition to the
creature types mentioned above.</p>
    <p>3. If you spend 6 additional power points, this power can
affect up to four creatures all within a 20-ft.-radius burst.</p>
    <p>In addition, for every 2 additional power points you spend to
achieve any of these effects, this power&rsquo;s save DC increases by 1.</p>
    <h6><a id="crisis-of-life"></a>Crisis of Life</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy [Mind-Affecting,
Death]</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Telepath 7
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Mental
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1
standard action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Medium (100 ft. +
10 ft./ level)
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> One creature
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Fortitude
partial; see text
    <span style={{
      "fontWeight": "bold"
    }}>Power Resistance:</span> Yes
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 13
    <p>You interrupt the subject&rsquo;s autonomic heart rhythm,
killing it
instantly on a failed saving throw if it has 11 Hit Dice or less. If
the target makes its saving throw or has more than 11 Hit Dice, it
takes 7d6 points of damage.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Augment:</span> For every
additional power point you spend, this power can kill a subject that
has Hit Dice equal to 11 + the number of additional points.</p>
    <h6><a id="crystal-shard"></a>Crystal Shard</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Metacreativity (Creation)</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Psion/wilder 1
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Auditory and
material
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1
standard action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Close (25 ft. + 5
ft./2 levels)
    <span style={{
      "fontWeight": "bold"
    }}>Effect:</span> Ray
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Instantaneous
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None
    <span style={{
      "fontWeight": "bold"
    }}>Power Resistance:</span> No
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 1
    <p>Upon manifesting this power, you propel a razor-sharp crystal
shard at your target. You must succeed on a ranged touch attack with
the ray to deal damage to a target. The ray deals 1d6 points of
piercing damage.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Augment:</span> For every
additional power point you spend, this power&rsquo;s damage increases
by 1d6
points.</p>
    <h6><a id="crystallize"></a>Crystallize</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Metacreativity</span>
    <span style={{
      "fontWeight": "bold"
    }}>Level:</span> Shaper 6
    <span style={{
      "fontWeight": "bold"
    }}>Display:</span> Auditory
    <span style={{
      "fontWeight": "bold"
    }}>Manifesting Time:</span> 1
standard action
    <span style={{
      "fontWeight": "bold"
    }}>Range:</span> Medium (100 ft. +
10 ft./ level)
    <span style={{
      "fontWeight": "bold"
    }}>Target:</span> One living
creature
    <span style={{
      "fontWeight": "bold"
    }}>Duration:</span> Permanent
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> Fortitude
negates
    <span style={{
      "fontWeight": "bold"
    }}>Power Resistance:</span> Yes
    <span style={{
      "fontWeight": "bold"
    }}>Power Points:</span> 11
    <p>You seed the subject&rsquo;s flesh with supersaturated
crystal. In
an eyeblink, the subject&rsquo;s form seems to freeze over, as its
flesh and
fluids are instantly crystallized. Following the application of this
power, the subject appears lifeless. In fact, it is not dead (though no
life can be detected with powers or spells that detect such).</p>
    <p>This power has a chance of being <a href="psionicPowersDtoF.html#dispel-psionics" style={{
        "color": "rgb(87, 158, 182)"
      }}>dispelled</a> only by a
manifester of a higher level than you when you manifested this power.
When the power is dispelled, crystal melts back into flesh, and the
subject is in exactly the state he was prior being affected by
crystallize.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      